interface requestOptions {
  method: string;
  baseUrl?: string;
  body?: Record<string, any>;
  include?: string;
}

interface requestConfig {
  method: requestOptions['method'];
  headers: {
    'Content-Type': string;
  };
  body?: string;
}

const DEFAULT_OPTIONS: requestOptions = {
  method: 'GET',
  baseUrl: '/api/storefront',
  body: {},
  include: '',
};

/**
 * Make API fetch request
 */
const request = async (requestUrl: string, opts: requestOptions) => {
  const options = { ...DEFAULT_OPTIONS, ...opts };
  const url = options.include?.length
    ? `${options.baseUrl}${requestUrl}?include=${options.include}`
    : `${options.baseUrl}${requestUrl}`;

  const config: requestConfig = {
    method: options.method,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (['GET', 'HEAD'].indexOf(options.method) === -1) {
    config.body = JSON.stringify(options.body);
  }

  try {
    const response = await fetch(url, config);

    if (!response.ok) {
      const message = `An error has occurred: ${response.status}`;
      throw new Error(message);
    }

    const json = await response.json();
    return json;
  } catch (error: any) {
    throw new Error(error);
  }
};

/**
 * Make API fetch request
 */
export default async function makeRequest(
  endpoint: string,
  options: requestOptions,
) {
  return await request(endpoint, { ...options });
}
